import { useEffect, useRef, useState } from 'react';
import { ILastScrollIndication } from '../../../../types';
import { MENU_PAGE_ITEMS, PAGE_IDS } from '../../../../config';

const getPageOffsetTop = (pageId: string): number => {
  return (document.getElementById(pageId)?.offsetTop ?? 0) - 300;
};

const getMenuItemsClasses = (activeMenuItem: string) => {
  const activeClass = 'active';
  return {
    [MENU_PAGE_ITEMS.home]:
      activeMenuItem === MENU_PAGE_ITEMS.home
        ? `${MENU_PAGE_ITEMS.home} ${activeClass}`
        : MENU_PAGE_ITEMS.home,
    [MENU_PAGE_ITEMS.showcase]:
      activeMenuItem === MENU_PAGE_ITEMS.showcase
        ? `${MENU_PAGE_ITEMS.showcase} ${activeClass}`
        : MENU_PAGE_ITEMS.showcase,
    [MENU_PAGE_ITEMS.skills]:
      activeMenuItem === MENU_PAGE_ITEMS.skills
        ? `${MENU_PAGE_ITEMS.skills} ${activeClass}`
        : MENU_PAGE_ITEMS.skills,
    [MENU_PAGE_ITEMS.projects]:
      activeMenuItem === MENU_PAGE_ITEMS.projects
        ? `${MENU_PAGE_ITEMS.projects} ${activeClass}`
        : MENU_PAGE_ITEMS.projects,
    [MENU_PAGE_ITEMS.contact]:
      activeMenuItem === MENU_PAGE_ITEMS.contact
        ? `${MENU_PAGE_ITEMS.contact} ${activeClass}`
        : MENU_PAGE_ITEMS.contact,
  };
};

const useActiveMenu = () => {
  const [activeMenuItems, setActiveMenuItems] = useState(
    getMenuItemsClasses(MENU_PAGE_ITEMS.home),
  );
  const lastScrollIndication = useRef<ILastScrollIndication>({
    scrollY: 0,
    isTicking: false,
  });

  const updateActiveMenu = () => {
    if (
      lastScrollIndication.current.scrollY >= getPageOffsetTop(PAGE_IDS.home) &&
      lastScrollIndication.current.scrollY <=
        getPageOffsetTop(PAGE_IDS.showcase)
    ) {
      setActiveMenuItems(getMenuItemsClasses(MENU_PAGE_ITEMS.home));
    } else if (
      lastScrollIndication.current.scrollY >=
        getPageOffsetTop(PAGE_IDS.showcase) &&
      lastScrollIndication.current.scrollY <= getPageOffsetTop(PAGE_IDS.skills)
    ) {
      setActiveMenuItems(getMenuItemsClasses(MENU_PAGE_ITEMS.showcase));
    } else if (
      lastScrollIndication.current.scrollY >=
        getPageOffsetTop(PAGE_IDS.skills) &&
      lastScrollIndication.current.scrollY <=
        getPageOffsetTop(PAGE_IDS.projects)
    ) {
      setActiveMenuItems(getMenuItemsClasses(MENU_PAGE_ITEMS.skills));
    } else if (
      lastScrollIndication.current.scrollY >=
        getPageOffsetTop(PAGE_IDS.projects) &&
      lastScrollIndication.current.scrollY <= getPageOffsetTop(PAGE_IDS.contact)
    ) {
      setActiveMenuItems(getMenuItemsClasses(MENU_PAGE_ITEMS.projects));
    } else if (
      lastScrollIndication.current.scrollY >= getPageOffsetTop(PAGE_IDS.contact)
    ) {
      setActiveMenuItems(getMenuItemsClasses(MENU_PAGE_ITEMS.contact));
    }
    lastScrollIndication.current.isTicking = false;
  };

  useEffect(() => {
    const requestTick = () => {
      if (!lastScrollIndication.current.isTicking) {
        requestAnimationFrame(updateActiveMenu);
        lastScrollIndication.current.isTicking = true;
      }
    };

    const onScroll = () => {
      lastScrollIndication.current.scrollY = window.scrollY;
      requestTick();
    };

    window.addEventListener('scroll', onScroll, false);

    return () => window.removeEventListener('scroll', onScroll, false);
  }, []);

  return {
    activeMenuItems,
  };
};

export default useActiveMenu;
