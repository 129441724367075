import React from 'react';
import Header from './layout/header';
import HomePage from './pages/home';
import ShowcasePage from './pages/showcase';
import SkillsPage from './pages/skills';
import ProjectsPage from './pages/projects';
import ContactPage from './pages/contact';

function App() {
  return (
    <div className="wrapper" data-testid="app-wrapper">
      <Header />

      <div className="container" id="container">
        <div className="main">
          <HomePage />

          <ShowcasePage />

          <SkillsPage />

          <ProjectsPage />

          <ContactPage />

          <footer className="mfb-copy-01">
            <p>&copy; MFB project Copyright 2016 - 2024</p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default App;
