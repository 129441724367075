import React, { useEffect } from 'react';
import mfbGoogleMarker from '../../assets/images/icon/mfb-google-maker.png';
import { Loader, LoaderOptions } from 'google-maps';

const ContactPage = () => {
  useEffect(() => {
    const fetchGoogleMaps = async () => {
      const options: LoaderOptions = {
        language: 'en',
      };
      const loader = new Loader(
        'AIzaSyC7bobk1Bcm2Veer19bncvGsVYtsNPC90I',
        options,
      );

      const google = await loader.load();

      const myLatlng = { lat: -33.978108, lng: 18.4794983 };

      const map = new google.maps.Map(
        document.getElementById('map-canvas') as HTMLElement,
        {
          center: myLatlng,
          zoom: 12,
          mapTypeId: 'roadmap',
          styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#212121',
                },
              ],
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#757575',
                },
              ],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#212121',
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#757575',
                },
              ],
            },
            {
              featureType: 'administrative.country',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#9e9e9e',
                },
              ],
            },
            {
              featureType: 'administrative.land_parcel',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#bdbdbd',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#757575',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#181818',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#616161',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#1b1b1b',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#2c2c2c',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#8a8a8a',
                },
              ],
            },
            {
              featureType: 'road.arterial',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#373737',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#3c3c3c',
                },
              ],
            },
            {
              featureType: 'road.highway.controlled_access',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#4e4e4e',
                },
              ],
            },
            {
              featureType: 'road.local',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#616161',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#757575',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f22307',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#3d3d3d',
                },
              ],
            },
          ],
        },
      );

      const contentString =
        '<div id="content" style="color:#000;">' +
        '<div id="bodyContent">' +
        '<h3>MFB Location</h3>' +
        '<ul>' +
        '<li>e: <a href="mailto:muzi@mfbproject.co.za" title="muzi@mfbproject.co.za" target="_blank">muzi@mfbproject.co.za</a></li>' +
        '<li>m: +27 81 427 4278</li>' +
        '</ul>' +
        '</div>' +
        '</div>';

      const infoWindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 200,
      });

      const marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        title: 'MFB Location',
        icon: mfbGoogleMarker,
      });

      google.maps.event.addListener(marker, 'click', function () {
        infoWindow.open(map, marker);
      });
    };

    fetchGoogleMaps();
  }, []);

  return (
    <div
      id="contact"
      className="page"
      data-page-slide="5"
      data-testid="page-contact"
    >
      <div className="border_page">&nbsp;</div>
      <div className="content">
        <div id="map-canvas">&nbsp;</div>
        <div className="row-fluid">
          <div className="span6">
            <h2>Contact info:</h2>
            <ul className="info">
              <li>
                <span className="profile-font-01">Email: </span>
                <a
                  href="mailto:muzi@mfbproject.co.za"
                  title="muzi@mfbproject.co.za"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  muzi@mfbproject.co.za
                </a>
              </li>
              <li>
                <span className="profile-font-01">Mobile:</span> +27 81 427 4278
              </li>
              <li>
                <span className="profile-font-01">GitHub: </span>
                <a
                  href="https://github.com/zulucoda/"
                  title="zuluCoda"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  zuluCoda
                </a>
              </li>
              <li>
                <span className="profile-font-01">LinkedIn: </span>
                <a
                  href="https://www.linkedin.com/in/muzikayise-flynn-buthelezi-50024315"
                  title="Muzikayise Flynn Buthelezi"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Muzikayise Flynn Buthelezi
                </a>
              </li>
              <li>
                <span className="profile-font-01">Blog: </span>
                <a
                  href="https://blog.mfbproject.co.za/"
                  title="https://blog.mfbproject.co.za/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  https://blog.mfbproject.co.za/
                </a>
              </li>
              <li>
                <span className="profile-font-01">Download CV: </span>
                <a
                  href="https://mfbproject.co.za/cv"
                  title="https://mfbproject.co.za/cv"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  https://mfbproject.co.za/cv
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
