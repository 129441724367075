import React from 'react';

const SkillsPage = () => {
  return (
    <div
      id="skills"
      className="page"
      data-page-slide="3"
      data-testid="page-skills"
    >
      <div className="border_page">&nbsp;</div>
      <div className="skills-content content">
        <h2>Experience</h2>

        <div className="row-fluid">
          <div className="span4">
            <h3>CEO Software Solutions, Remote, Global [CTO | Architect]</h3>

            <p className="period">2022-Present</p>

            <p>
              Our technology is engineered to augment C-level strategic decision-making at the world's leading companies.
            </p>
          </div>

          <div className="span4">
            <h3>Allan Gray, Cape Town [Tech Lead]</h3>

            <p className="period">2019-2022</p>

            <p>
              Web application development with JavaScript, TypeScript, Node,
              Express, React, Hooks, BFF, IO-TS, Formik, Micro Front-end, Jest,
              HTML5, CSS.
            </p>
          </div>

          <div className="span4">
            <h3>Property Finder - Dubai, UAE [Senior Frontend Developer]</h3>

            <p className="period">2018-2019</p>

            <p>
              Web / Mobile application development - JavaScript, TypeScript,
              React, Redux, Redux-Saga, Jest, HTML5, CSS.
            </p>
          </div>
        </div>

        <hr />

        <div className="row-fluid">
          <div className="span4">
            <h3>Liberty - dHUB, Braamfontein [Full Stack Developer]</h3>

            <p className="period">2017-2018</p>

            <p>
              Web / Mobile application development with Full Stack JavaScript -
              React, React Native, Redux, HTML5, CSS, NodeJS, Express, MongoDB,
              Jest.
            </p>
          </div>

          <div className="span4">
            <h3>Dariel, Waverley [Tech Lead]</h3>

            <p className="period">2014-2017</p>

            <p>
              Web application development with JavaScript - AngularJS, HTML5,
              CSS, NodeJS, GruntJS, Jasmine, Karma.
            </p>
          </div>

          <div className="span4">
            <h3>BBD, Houghton [Software Developer]</h3>

            <p className="period">2012 - 2014</p>

            <p>
              Software Developer (python, C#, bash) on AIX (Unix) and Windows.
            </p>
          </div>
        </div>

        <hr />

        <div className="row-fluid">
          <div className="span4">
            <h3>
              Metrix, Fourways [Senior .NET Developer and .NET Process Owner]
            </h3>

            <p className="period">2010 - 2012</p>

            <p>
              Web-based application development with ASP.NET (C#), with CSS,
              XHTML and JavaScript.
            </p>
          </div>

          <div className="span4">
            <h3>iScrip, Midrand - [Analyst Programmer]</h3>

            <p className="period">2009 - 2010</p>

            <p>
              Credit Vetting System – while at iScrip Software Solution
              (www.iscript.co.za) I worked on critical components for an online
              credit vetting system for one of South Africa’s leading cellular
              operators.
            </p>
          </div>

          <div className="span4">
            <h3>amaDigital, Randburg - [Head Developer]</h3>

            <p className="period">2005 - 2009</p>

            <p>
              Web site development using ASP, ASP.NET, using MS SQL DB.
              Web-based application development with ASP.NET, using MS SQL DB on
              IIS
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsPage;
