import React from 'react';
import { IHeaderWrapper } from '../../types';
import styled from 'styled-components';
import useHeaderSize from './hooks/header-size';
import useActiveMenu from './hooks/active-menu';
import { MENU_PAGE_ITEMS } from '../../config';

const HeaderWrapper = styled.div<IHeaderWrapper>`
  margin-left: ${(props) => props.headerSize}px;
`;

const Header = () => {
  const { activeMenuItems } = useActiveMenu();
  const { headerSize } = useHeaderSize();

  return (
    <HeaderWrapper className="header" headerSize={headerSize}>
      <ul className="menu_nav">
        <li
          id="home_page"
          className={`${activeMenuItems[MENU_PAGE_ITEMS.home]}`}
        >
          <a href="#home" data-page-slide="1">
            <i className="icon" />
            <span>Home</span>
          </a>
        </li>
        <li
          id="showcase_page"
          className={`${activeMenuItems[MENU_PAGE_ITEMS.showcase]}`}
        >
          <a href="#showcase" data-page-slide="2">
            <i className="icon" />
            <span>Showcase</span>
          </a>
        </li>
        <li
          id="skills_page"
          className={`${activeMenuItems[MENU_PAGE_ITEMS.skills]}`}
        >
          <a href="#skills" data-page-slide="3">
            <i className="icon" />
            <span>Skills</span>
          </a>
        </li>
        <li
          id="projects_page"
          className={`${activeMenuItems[MENU_PAGE_ITEMS.projects]}`}
        >
          <a href="#projects" data-page-slide="4">
            <i className="icon" />
            <span>Projects</span>
          </a>
        </li>
        <li
          id="contact_page"
          className={`${activeMenuItems[MENU_PAGE_ITEMS.contact]}`}
        >
          <a href="#contact" data-page-slide="5">
            <i className="icon" />
            <span>Contact</span>
          </a>
        </li>
      </ul>
    </HeaderWrapper>
  );
};

export default Header;
