import React from 'react';
import reactTunePlayerThumb from '../../assets/images/open-sources-projects/thumb/react-tunes-player-thumb.png';
import reactSwiftSliderThumb from '../../assets/images/open-sources-projects/thumb/react-swift-slider-thumb.png';
import SliderExample from './slider-example';
import styled from 'styled-components';
import {
  IDemoLink,
  IInstallCode,
  INpmBadges,
  IRepositoryLink,
} from '../../types';
import GitSvg from '../../assets/svgs/git';
import LinkSvg from '../../assets/svgs/link';

const InstallHeading = styled.h4`
  line-height: 2.3;
`;

const InstallCodeWrapper = styled.div`
  margin-bottom: 35px;
`;

const NpmBadge = styled.a`
  padding: 0 5px 0 0;
`;

const CodeInstall = styled.code`
  padding: 15px;
  font-size: 13px;
`;

const SvgWrapper = styled.span`
  width: 18px;
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
`;

const Line = styled.hr`
  margin: 20px 0 20px 0;
`;

const NpmBadges = ({ repoName }: INpmBadges) => {
  return (
    <p className="bottom-margin">
      <NpmBadge
        href={`https://www.npmjs.com/package/${repoName}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <img
          src={`https://img.shields.io/npm/v/${repoName}.svg`}
          alt="npm status"
        />
      </NpmBadge>
      <NpmBadge
        href={`https://app.travis-ci.com/zulucoda/${repoName}`}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        <img
          src={`https://app.travis-ci.com/zulucoda/${repoName}.svg?branch=master`}
          alt="Build Status"
        />
      </NpmBadge>
      <NpmBadge
        href={`https://coveralls.io/github/zulucoda/${repoName}?branch=master`}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        <img
          src={`https://coveralls.io/repos/github/zulucoda/${repoName}/badge.svg?branch=master`}
          alt="Coverage Status"
        />
      </NpmBadge>
    </p>
  );
};

const InstallCode = ({ npmInstall }: IInstallCode) => {
  return (
    <InstallCodeWrapper>
      <InstallHeading>Install</InstallHeading>
      <CodeInstall>{npmInstall}</CodeInstall>
    </InstallCodeWrapper>
  );
};

const RepositoryLink = ({ repoLink, repoName }: IRepositoryLink) => {
  return (
    <div className="bottom-margin">
      <h4>Repository</h4>
      <p>
        <a
          aria-labelledby="repository"
          href={repoLink}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <SvgWrapper>
            <GitSvg />
          </SvgWrapper>
          <span>{repoName}</span>
        </a>
      </p>
    </div>
  );
};

const DemoLink = ({ demoLink, demoName }: IDemoLink) => {
  return (
    <div className="bottom-margin">
      <h4>Demo</h4>
      <p>
        <a
          aria-labelledby="demo"
          href={demoLink}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <SvgWrapper>
            <LinkSvg />
          </SvgWrapper>
          <span>{demoName}</span>
        </a>
      </p>
    </div>
  );
};

const ProjectsPage = () => {
  return (
    <div
      id="projects"
      className="page"
      data-page-slide="4"
      data-testid="page-projects"
    >
      <div className="border_page">&nbsp;</div>
      <div className="content">
        <h2>Open Source Projects:</h2>

        <hr />

        <div className="row-fluid">
          <div className="span8">
            <h2 className="bottom-margin">React Tunes Player</h2>

            <NpmBadges repoName="react-tunes-player" />

            <p className="bottom-margin">
              A simple .ogg/.mp3 player. The updated version now uses React
              hooks, whereas the previous version used Redux.
            </p>

            <InstallCode npmInstall="npm i react-tunes-player" />

            <Line />

            <RepositoryLink
              repoLink="https://github.com/zulucoda/react-tunes-player"
              repoName="github.com/zulucoda/react-tunes-player"
            />

            <Line />

            <DemoLink
              demoLink="https://react-tunes-player.mfbproject.co.za/"
              demoName="https://react-tunes-player.mfbproject.co.za/"
            />

            <Line />

            <iframe
              src="https://react-tunes-player.mfbproject.co.za/"
              width="100%"
              height="80"
              className="react-tunes-player"
              title="react-tunes-player"
            />
          </div>
          <div className="span4">
            <div className="hsbc_showcase">
              <a
                href="https://www.npmjs.com/package/react-tunes-player"
                className="item"
                title="https://www.npmjs.com/package/react-tunes-player"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img
                  src={reactTunePlayerThumb}
                  title="https://www.npmjs.com/package/react-tunes-player"
                  alt="React Tunes Player"
                />
              </a>
            </div>
          </div>
        </div>

        <hr />

        <div className="row-fluid">
          <div className="span8">
            <h2 className="bottom-margin">React Swift Slider</h2>

            <NpmBadges repoName="react-swift-slider" />

            <p className="bottom-margin">
              React Swift Slider is a simple image/picture slider for react.
            </p>

            <InstallCode npmInstall="npm i react-swift-slider" />

            <Line />

            <RepositoryLink
              repoLink="https://github.com/zulucoda/react-swift-slider"
              repoName="github.com/zulucoda/react-swift-slider"
            />

            <Line />

            <DemoLink
              demoLink="https://react-swift-slider.mfbproject.co.za/"
              demoName="https://react-swift-slider.mfbproject.co.za/"
            />

            <Line />

            <SliderExample />
          </div>
          <div className="span4">
            <div className="hsbc_showcase">
              <a
                href="https://www.npmjs.com/package/react-swift-slider"
                className="item"
                title="https://www.npmjs.com/package/react-swift-slider"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img
                  src={reactSwiftSliderThumb}
                  title="https://www.npmjs.com/package/react-swift-slider"
                  alt="React Swift Slider"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
