import mfbZuluCoda from '../../assets/images/muzikayise_flynn_buthelezi_zulucoda.jpg';
import React from 'react';
import styled from 'styled-components';
import { MFB_LINKS } from '../../config';

const ButtonBoxWrapper = styled.div`
  margin: 65px 0 0;

  @media (max-width: 1250px) {
    margin: 0;
  }
`;

const HomePage = () => {
  return (
    <div id="home" className="page" data-page-slide="1" data-testid="page-home">
      <div className="border_page">&nbsp;</div>
      <div className="home-content content">
        <div className="row-fluid">
          <div className="span6">
            <h1>Muzikayise Flynn Buthelezi</h1>
            <h4>Front-End Developer, Full Stack JavaScript Developer</h4>
            <ButtonBoxWrapper>
              <div className="button_box scroll_btns">
                <a
                  href={MFB_LINKS.cv}
                  className="button"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Download CV
                </a>
                <a
                  href={MFB_LINKS.blog}
                  target="_blank"
                  className="button"
                  rel="noopener noreferrer nofollow"
                >
                  Blog
                </a>
              </div>
              <div className="button_box scroll_btns">
                <a
                  href={MFB_LINKS.gitHub}
                  className="button"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  GitHub
                </a>
                <a
                  href={MFB_LINKS.linkedIn}
                  target="_blank"
                  className="button"
                  rel="noopener noreferrer nofollow"
                >
                  LinkedIn
                </a>
              </div>
            </ButtonBoxWrapper>
          </div>
          <div className="span6">
            <div className="avatar">
              <img src={mfbZuluCoda} alt="Muzikayise Flynn Buthelezi" />
            </div>
          </div>
        </div>
        <div className="row-fluid">
          <div className="span12">
            <h2>About me:</h2>

            <p>
              I have been doing software development professionally since 2005.
              My passion for programming started when I was a junior in high
              school. My first computer program was an 8-bit happy birthday song
              written in the PASCAL programming language. Furthermore, I have
              had the pleasure of working in various industries using various
              tools, technologies and practices.
            </p>

            <hr />

            <strong>Core Technology Summary</strong>
            <ul className="info info-01 info-disc">
              <li>
                8+ years JavaScript experience with SPAs (Single Page
                Applications)
              </li>
              <li>
                10+ years experience with full stack web application development
              </li>
              <li>5+ years experience with React web applications</li>
            </ul>

            <hr />
            <strong>Supplemental Technology Summary</strong>
            <ul className="info info-01 info-disc">
              <li>Experience with Nodejs and Express.</li>
              <li>Experience with migrating from any old SPA to React.</li>
              <li>Experience with Micro Front-end architecture.</li>
              <li>Experience with Complex Workflows.</li>
            </ul>

            <hr />
            <strong>Leadership Capabilities</strong>
            <ul className="info info-01 info-disc">
              <li>
                Leading my team's overall technical delivery and proactively
                improving the team's software engineering practices &
                disciplines.
              </li>
              <li>Proactively coaching junior & intermediate developers.</li>
              <li>Managing Non-Functional work and Tech-Debt.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
