import React from 'react';

const ShowcasePage = () => {
  return (
    <div
      id="showcase"
      className="page"
      data-page-slide="2"
      data-testid="page-showcase"
    >
      <div className="border_page">&nbsp;</div>
      <div className="skills-content content">
        <h2>Showcase</h2>

        <hr />

        <div className="row-fluid">
          <div className="span7">
            <h2 className="bottom-margin">Full Stack JavaScript</h2>

            <p>
              <strong>Front-end Stack: </strong> React, Redux, Redux-Saga,
              Material-ui
            </p>
            <p className="bottom-margin">
              <strong>Back-end Stack: </strong> Nodejs, Expressjs, Sequelize ORM
              & CLI, PostgreSQL
            </p>

            <p>
              <strong>Live Demo:</strong>{' '}
              <a
                href="https://muzi-mini-system.mfbproject.co.za/app"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="https://muzi-mini-system.mfbproject.co.za/app"
              >
                https://muzi-mini-system.mfbproject.co.za/app
              </a>
            </p>
            <p className="bottom-margin">
              <strong>
                Note: Requires Login Credentials.{' '}
                <a
                  href="mailto:muzi@mfbproject.co.za"
                  title="muzi@mfbproject.co.za"
                >
                  E-mail me for Login Credentials
                </a>
              </strong>
            </p>

            <p>
              <strong>GitHub Code:</strong>
            </p>
            <p>
              <strong>Front-end: </strong>{' '}
              <a
                href="https://github.com/zulucoda/muzi-mini-system-app"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="https://github.com/zulucoda/muzi-mini-system-app"
              >
                https://github.com/zulucoda/muzi-mini-system-app
              </a>
            </p>
            <p className="bottom-margin">
              <strong>Back-end: </strong>{' '}
              <a
                href="https://github.com/zulucoda/muzi-mini-system-api"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="https://github.com/zulucoda/muzi-mini-system-api"
              >
                https://github.com/zulucoda/muzi-mini-system-api
              </a>
            </p>

            <div>
              <p>
                <strong>Sample Screens:</strong>
              </p>
              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-01-1024x576.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://github.com/zulucoda/muzi-mini-system-app"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-01-150x150.png"
                  title="https://github.com/zulucoda/muzi-mini-system-app"
                  alt="https://github.com/zulucoda/muzi-mini-system-app"
                />
              </a>

              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-03-1024x525.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://github.com/zulucoda/muzi-mini-system-app"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-03-150x150.png"
                  title="https://github.com/zulucoda/muzi-mini-system-app"
                  alt="https://github.com/zulucoda/muzi-mini-system-app"
                />
              </a>

              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-04-1024x439.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://github.com/zulucoda/muzi-mini-system-app"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-04-150x150.png"
                  title="https://github.com/zulucoda/muzi-mini-system-app"
                  alt="https://github.com/zulucoda/muzi-mini-system-app"
                />
              </a>

              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-05-1024x451.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://github.com/zulucoda/muzi-mini-system-app"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-05-150x150.png"
                  title="https://github.com/zulucoda/muzi-mini-system-app"
                  alt="https://github.com/zulucoda/muzi-mini-system-app"
                />
              </a>
            </div>
          </div>
          <div className="span5">
            <div className="hsbc_showcase">
              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-02-1024x524.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://github.com/zulucoda/muzi-mini-system-app"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/muzi-mini-system-app-02-1024x524.png"
                  title="https://github.com/zulucoda/muzi-mini-system-app"
                  alt="https://github.com/zulucoda/muzi-mini-system-app"
                />
              </a>
            </div>

            <p className="bottom-margin">
              <strong>Description:</strong> Simple CRUD application. The
              Front-end SPA consumes the Back-end RESTful API using JSON data.
              Used PostgreSQL because the data was much easier to store using a
              relational database.
            </p>
            <p className="bottom-margin">
              <strong>More info:</strong>{' '}
              <a
                href="https://github.com/zulucoda/muzi-mini-system-app/blob/master/README.md"
                title="https://github.com/zulucoda/muzi-mini-system-app/blob/master/README.md"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                https://github.com/zulucoda/muzi-mini-system-app/blob/master/README.md
              </a>
            </p>
          </div>
        </div>

        <hr />

        <div className="row-fluid">
          <div className="span7">
            <h2 className="bottom-margin">Front-end React</h2>

            <p className="bottom-margin">
              <strong>Stack: </strong> React, Redux, Redux-Thunk, Bootstrap,
              SASS
            </p>

            <p className="bottom-margin">
              <strong>Live Demo:</strong>{' '}
              <a
                href="https://travel-agency.mfbproject.co.za/"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="https://travel-agency.mfbproject.co.za/"
              >
                https://travel-agency.mfbproject.co.za/
              </a>
            </p>

            <p className="bottom-margin">
              <strong>GitHub Code: </strong>
              <a
                href="https://github.com/zulucoda/travel-agency"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="https://github.com/zulucoda/travel-agency"
              >
                https://github.com/zulucoda/travel-agency
              </a>
            </p>

            <div>
              <p>
                <strong>Sample Screens:</strong>
              </p>
              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-02-1024x576.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://travel-agency.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-02-150x150.png"
                  title="https://travel-agency.mfbproject.co.za/"
                  alt="https://travel-agency.mfbproject.co.za/"
                />
              </a>

              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-03-1024x576.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://travel-agency.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-03-150x150.png"
                  title="https://travel-agency.mfbproject.co.za/"
                  alt="https://travel-agency.mfbproject.co.za/"
                />
              </a>

              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-04-1024x576.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://travel-agency.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-04-150x150.png"
                  title="https://travel-agency.mfbproject.co.za/"
                  alt="https://travel-agency.mfbproject.co.za/"
                />
              </a>

              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-05-1024x576.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://travel-agency.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-05-150x150.png"
                  title="https://travel-agency.mfbproject.co.za/"
                  alt="https://travel-agency.mfbproject.co.za/"
                />
              </a>
            </div>
          </div>
          <div className="span5">
            <div className="hsbc_showcase">
              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-01-1024x576.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://travel-agency.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-travel-agency-01-1024x576.png"
                  title="https://travel-agency.mfbproject.co.za/"
                  alt="https://travel-agency.mfbproject.co.za/"
                />
              </a>
            </div>

            <p className="bottom-margin">
              <strong>Description:</strong> Simple web app to display travel
              options between cities.
            </p>
            <p className="bottom-margin">
              <strong>More info:</strong>{' '}
              <a
                href="https://github.com/zulucoda/travel-agency/blob/master/README.md"
                title="https://github.com/zulucoda/travel-agency/blob/master/README.md"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                https://github.com/zulucoda/travel-agency/blob/master/README.md
              </a>
            </p>
          </div>
        </div>

        <hr />

        <div className="row-fluid">
          <div className="span7">
            <h2 className="bottom-margin">Front-end Angularjs (1.5)</h2>

            <p className="bottom-margin">
              <strong>Stack: </strong> Angularjs (1.5) *Webpack workflow, Babel
              (ES6), SASS, Bootstrap
            </p>

            <p className="bottom-margin">
              <strong>Live Demo:</strong>{' '}
              <a
                href="https://zulucoda-scrum.mfbproject.co.za/"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="https://zulucoda-scrum.mfbproject.co.za/"
              >
                https://zulucoda-scrum.mfbproject.co.za/
              </a>
            </p>

            <p className="bottom-margin">
              <strong>GitHub Code: </strong>
              <a
                href="https://github.com/zulucoda/zulucoda-scrum"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="https://github.com/zulucoda/zulucoda-scrum"
              >
                https://github.com/zulucoda/zulucoda-scrum
              </a>
            </p>

            <div>
              <p>
                <strong>Sample Screens:</strong>
              </p>
              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-02-1024x576.png"
                className="item"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="https://zulucoda-scrum.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-02-150x150.png"
                  title="https://zulucoda-scrum.mfbproject.co.za/"
                  alt="https://zulucoda-scrum.mfbproject.co.za/"
                />
              </a>

              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-03-1024x576.png"
                className="item"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="https://zulucoda-scrum.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-03-150x150.png"
                  title="https://zulucoda-scrum.mfbproject.co.za/"
                  alt="https://zulucoda-scrum.mfbproject.co.za/"
                />
              </a>

              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-04-1024x576.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://zulucoda-scrum.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-04-150x150.png"
                  title="https://zulucoda-scrum.mfbproject.co.za/"
                  alt="https://zulucoda-scrum.mfbproject.co.za/"
                />
              </a>

              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-05-1024x576.png"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="item"
                title="https://zulucoda-scrum.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-05-150x150.png"
                  title="https://zulucoda-scrum.mfbproject.co.za/"
                  alt="https://zulucoda-scrum.mfbproject.co.za/"
                />
              </a>
            </div>
          </div>
          <div className="span5">
            <div className="hsbc_showcase">
              <a
                href="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-01-1024x576.png"
                rel="lightbox"
                className="item"
                title="https://zulucoda-scrum.mfbproject.co.za/"
              >
                <img
                  src="https://blog.mfbproject.co.za/wp-content/uploads/2019/02/zulucoda-scrum-01-1024x576.png"
                  title="https://zulucoda-scrum.mfbproject.co.za/"
                  alt="https://zulucoda-scrum.mfbproject.co.za/"
                />
              </a>
            </div>

            <p className="bottom-margin">
              <strong>Description:</strong> A simple application that
              facilitates the Scrum Methodology.
            </p>
            <p className="bottom-margin">
              <strong>More info:</strong>{' '}
              <a
                href="https://github.com/zulucoda/zulucoda-scrum/blob/master/README.md"
                title="https://github.com/zulucoda/zulucoda-scrum/blob/master/README.md"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                https://github.com/zulucoda/zulucoda-scrum/blob/master/README.md
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowcasePage;
