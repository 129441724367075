import { IHeaderWrapper, ISize } from '../../../../types';
import { useEffect, useState } from 'react';

const useHeaderSize = (): IHeaderWrapper => {
  const [windowSize, setWindowSize] = useState<ISize>({
    width: undefined,
    height: undefined,
  });

  const [headerSize, setHeaderSize] = useState<number>(0);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowSize.width && windowSize.height) {
      const header = document.getElementById('header');
      const headerWidth = header?.offsetWidth ?? 0;
      const containerWidth =
        document.getElementById('container')?.offsetWidth ?? 0;
      if (windowSize.width > 1450) {
        const calculatedHeaderSize = -(containerWidth / 1.82 + headerWidth);
        setHeaderSize(calculatedHeaderSize);
      }
      if (windowSize.width < 1450 && windowSize.width > 1300) {
        setHeaderSize(-585);
      }
      if (windowSize.width < 1300) {
        setHeaderSize(0);
      }
    }
  }, [windowSize]);

  return {
    headerSize,
  };
};

export default useHeaderSize;
