import React from 'react';
import ReactDOM from 'react-dom';
import './assets/vendor/css/bootstrap.min.css';
import './assets/vendor/css/bootstrap-responsive.min.css';
import icons from './assets/images/icon/icon.png';
import showcaseIcon from './assets/images/icon/showcase.png';
import skillsIcon from './assets/images/icon/rating.png';
import projectsIcon from './assets/images/icon/open-source.png';
import contactIcon from './assets/images/icon/mobile-app.png';
import homeIcon from './assets/images/icon/worker.png';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components';
import { IGlobalStyle } from './types';
import { ReactGA, sendWebVitalsToGoogleAnalytics } from './analytics';

const GlobalStyle = createGlobalStyle<IGlobalStyle>`
  /*!
   * Theme Styles
   * Created by Muzikayise Flynn Buthelezi on 2021/09/24.
   * mfbproject.co.za - muzi@mfbproject.co.za
   * Copyright MFBproject
   */

  /*reset*/
  html {
    color: #000;
    background: #2F3238;
    scroll-behavior: smooth;
  }

  body,
  div,
  dl, dt, dd,
  ul, ol, li,
  h1, h2, h3, h4, h5, h6,
  pre, code,
  form, fieldset,
  legend, input, textarea,
  p, blockquote, th, td {
    margin: 0;
    padding: 0;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  fieldset,
  img {
    border: 0;
  }

  address, caption, cite,
  code, dfn, em, th, var {
    font-style: normal;
    font-weight: normal;
  }

  li {
    list-style: none;
  }

  caption, th {
    text-align: left;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal;
  }

  q:before, q:after {
    content: '';
  }

  abbr, acronym {
    border: 0;
    font-variant: normal;
  }

  sup {
    vertical-align: text-top;
  }

  sub {
    vertical-align: text-bottom;
  }

  input, textarea, select {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  input, textarea, select {
    *font-size: 100%;
  }

  legend {
    color: #000;
  }

  a, a:focus, a:active {
    outline: 0 !important;
  }

  /* CSS Document */
  html, body {
    width: 100%;
    font-size: 16px;
  }

  body {
    font: 14px/2 Georgia;
    background-color: #2F3238 !important;
  }

  h1, h2, h3, p {
  }

  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  .uneditable-input {
    border-radius: 0;
    box-shadow: none !important;
  }

  a, a:hover {
    color: #232527;
  }

  h1, h2, h3, h4 {
    line-height: 1.3;
  }

  .menu_nav li.home_page.active,
  #home .home-content h2,
  .menu_nav li.showcase_page.active,
  #showcase .skills-content h2,
  .menu_nav li.skills_page.active,
  #skills .skills-content h2,
  .menu_nav li.projects_page.active,
  #projects h2,
  .menu_nav li.contact_page.active,
  #contact h2,
  #home .border_page,
  #home .button_box a.button,
  #skills .border_page,
  .skills-content .item-progress .progress,
  #projects .border_page,
  #contact .border_page,
  #contact .form .button,
  #showcase .form .button,
  #home .button_box a.button:hover,
  #contact .form .button:hover,
  #showcase .form .button:hover {
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }
  
  /* ---------Colors Scheme5----------- */
  .colorScheme-5 .menu_nav li.home_page.active,
  .colorScheme-5 #home .home-content h2,
  .colorScheme-5 .menu_nav li.skills_page.active,
  .colorScheme-5 #skills .skills-content h2,
  .colorScheme-5 .menu_nav li.showcase_page.active,
  .colorScheme-5 #showcase .skills-content h2,
  .colorScheme-5 .menu_nav li.projects_page.active,
  .colorScheme-5 #projects h2,
  .colorScheme-5 .menu_nav li.contact_page.active,
  .colorScheme-5 #contact h2 {
    border-color: #F22307;
  }

  .colorScheme-5 #home .border_page,
  .colorScheme-5 #home .button_box a.button,
  .colorScheme-5 #skills .border_page,
  .colorScheme-5 #showcase .border_page,
  .colorScheme-5 .skills-content .item-progress .progress,
  .colorScheme-5 #projects .border_page,
  .colorScheme-5 #contact .border_page,
  .colorScheme-5 #contact .form .button,
  .colorScheme-5 #showcase .form .button {
    background: #F22307;
  }

  .colorScheme-5 #home .button_box a.button:hover,
  .colorScheme-5 #contact .form .button:hover,
  .colorScheme-5 #showcase .form .button:hover {
    background: #EC810D;
  }

  /* --------------------------------- */

  /* ----------Border Scheme1 --------- */
  .borderScheme-1 .menu_nav li {
    border-radius: 25px 0 0 23px;
  }

  .borderScheme-1 .main .content h2 {
    border-radius: 27px 0 0 27px;
  }

  .borderScheme-1 .wrapper .button,
  .borderScheme-1 #contact .form input.button,
  .borderScheme-1 #showcase .form input.button {
    border-radius: 4px;
  }

  .borderScheme-1 .main .page .border_page {
    border-radius: 46px 46px 0 0;
  }

  .borderScheme-1 .main .page .content {
    border-radius: 15px 15px 0 0;
  }

  .borderScheme-1 .skills-content .item-progress .my-progress-bar {
    border-radius: 4px;
  }

  .borderScheme-1 #contact .form input,
  .borderScheme-1 #showcase .form input,
  .borderScheme-1 #contact .form textarea,
  .borderScheme-1 #showcase .form textarea {
    border-radius: 5px;
  }


  .wrapper .icon {
    background: url(${(props) => props.icons}) no-repeat;
    display: inline-block;
    vertical-align: middle;
  }

  .wrapper .button,
  #contact .form input.button,
  #showcase .form input.button {
    height: 42px;
    line-height: 42px;
    width: 200px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-family: Oswald, sans-serif;
    text-decoration: none !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .wrapper .container {
    position: relative;
    background-color: #2F3238 !important;
  }

  .progress {
    filter: none;
  }

  /* ----------- Header --------------*/
  .header {
    position: fixed;
    top: 100px;
    left: 50%;
  }

  .menu_nav li {
    line-height: 50px;
    margin: 15px 0;
    width: 110px;
    box-shadow: 0px 0 7px #746C6C;
    background-color: rgba(162, 153, 153, 0.21);
    border-left: 10px solid #777;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }


  .menu_nav li:hover {
    background-color: #fff;
    -webkit-transform: translate(-60px);
    -moz-transform: translate(-60px);
    -ms-transform: translate(-60px);
    transform: translate(-60px);
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }

  .menu_nav li a {
    padding: 0 0 0 16px;
    display: block;
    white-space: nowrap;
    font-family: Oswald, sans-serif;
    font-size: 16px;
    color: #232527;
    text-decoration: none !important;
  }

  .menu_nav li a .icon {
    width: 24px;
    height: 24px;
    margin: 0 10px 5px 0;
  }

  .menu_nav li.home_page a .icon {
    background-repeat: no-repeat;
    background-image: url(${(props) => props.homeIcon});
    display: inline-block;
    vertical-align: middle;
    background-size: 24px 24px;
    background-position: 0px 0px;
  }

  .menu_nav li.contact_page a .icon {
    background-repeat: no-repeat;
    background-image: url(${(props) => props.contactIcon});
    display: inline-block;
    vertical-align: middle;
    background-size: 24px 24px;
    background-position: 0px 0px;
  }

  .menu_nav li.skills_page a .icon {
    background-repeat: no-repeat;
    background-image: url(${(props) => props.skillsIcon});
    display: inline-block;
    vertical-align: middle;
    background-size: 24px 24px;
    background-position: 0px 0px;
  }

  .menu_nav li.projects_page a .icon {
    background-repeat: no-repeat;
    background-image: url(${(props) => props.projectsIcon});
    display: inline-block;
    vertical-align: middle;
    background-size: 24px 24px;
    background-position: 0px 0px;
  }

  .menu_nav li.showcase_page a .icon {
    background-repeat: no-repeat;
    background-image: url(${(props) => props.showcaseIcon});
    display: inline-block;
    vertical-align: middle;
    background-size: 24px 24px;
    background-position: 0px 0px;
  }

  .menu_nav li.active {
    background-color: #fff;
    -webkit-transform: translate(-60px);
    -moz-transform: translate(-60px);
    -ms-transform: translate(-60px);
    transform: translate(-60px);
  }

  /* -------------- Main Content ------------*/
  .main {
    height: 100%;
    min-height: 100%;
    padding: 0 0 50px;
  }

  .main .page {
    padding: 50px 0 0;
    margin: 0 0 100px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .main .page .border_page {
    width: 96%;
    margin: 0 auto;
    height: 15px;
  }

  .main .page .content {
    position: relative;
    width: 100%;
    background: #fff;
    border-top: 3px solid #D3C1C1;
    border-bottom: 4px solid #bebebe;
    padding: 0 4% 65px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .main .content h1 {
    font-size: 50px;
    margin: 0 0 25px;
    font-family: "Oswald", sans-serif;
    margin: 40px 0 0;
  }

  .main .content h4 {
    font-size: 17px;
    margin: 20px 0 5px;
    color: #979797;
  }

  .main .content h2 {
    font-size: 24px;
    padding: 0 18px;
    font-family: "Oswald", sans-serif;
    border-left: 5px solid;
    color: #232527;
  }

  .social_icon {
    width: 90%;
    padding: 15px 0 0 0;
    text-align: center;
  }

  .social_icon-2 {
    text-align: left !important;
  }

  .social_icon li {
    margin: 0 0 10px;
    width: 32%;
    float: left;
  }

  .social_icon li a {
    display: inline-block;
    line-height: 34px;
    color: #232527;
    font-size: 13px;
  }

  .social_icon li a .icon {
    height: 34px;
    width: 34px;
    margin: 0 5px 0 0;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .social_icon li a.twitter .icon {
    background-position: -254px -82px;
  }

  .social_icon li a.facebook .icon {
    background-position: 0 -82px;
  }

  .social_icon li a.linkedin .icon {
    background-position: -127px -82px;
  }

  .social_icon li a.vimeo .icon {
    background-position: -341px -82px;
  }

  .social_icon li a.flickr .icon {
    background-position: -42px -82px;
  }

  .social_icon li a.instagram .icon {
    background-position: -84px -82px;
  }

  .social_icon li a.pinterest .icon {
    background-position: -170px -82px;
  }

  .social_icon li a.youtube .icon {
    background-position: -298px -82px;
  }

  .social_icon li a.tumblr .icon {
    background-position: -210px -82px;
  }

  .social_icon li a.twitter:hover .icon {
    background-position: -254px -43px;
  }

  .social_icon li a.facebook:hover .icon {
    background-position: 0 -43px;
  }

  .social_icon li a.linkedin:hover .icon {
    background-position: -127px -43px;
  }

  .social_icon li a.vimeo:hover .icon {
    background-position: -341px -43px;
  }

  .social_icon li a.flickr:hover .icon {
    background-position: -42px -43px;
  }

  .social_icon li a.instagram:hover .icon {
    background-position: -84px -43px;
  }

  .social_icon li a.pinterest:hover .icon {
    background-position: -170px -43px;
  }

  .social_icon li a.youtube:hover .icon {
    background-position: -298px -43px;
  }

  .social_icon li a.tumblr:hover .icon {
    background-position: -210px -43px;
  }

  /* ------------- Home Page -------------- */
  #home h1,
  #home h4 {
    text-align: left;
  }

  #home .home-content .avatar {
    width: 70%;
    margin: 20px auto 0;
    background: #E4DEDE;
    padding: 10px;
    border-radius: 50%;
  }

  #home .home-content .avatar img {
    width: 100%;
    display: block;
    border-radius: 50%;
  }

  #home .home-content p {
    margin: 10px 0 0;
    padding: 0 20px 0 0;
  }

  #home .home-content ul.info {
    margin: 14px 0 0;
  }

  #home .home-content ul.info li {
    margin: 0 0 8px;
  }

  #home .home-content ul.info li span {
    font-size: 14px;
    margin: 0 10px 0 0;
    text-transform: uppercase;
  }

  #home .social_icon {
    margin: 0 auto;
    overflow: hidden;
  }

  #home .button_box {
    margin: 25px 0 0;
    text-align: left;
    width: 100%;
  }

  #home .button_box a.button {
    display: inline-block;
    margin: 0 20px 0 0;
  }

  /* -------------- Skils page ---------------- */
  .skills-content h2 {
    margin: 35px 0 10px;
  }

  .skills-content h3 {
    margin: 0 0 10px;
    font-size: 18px;
    padding: 0 0 3px;
    border-bottom: 1px dashed #D3D6DF;
  }

  .skills-content .period {
    margin: 0 0 10px;
  }

  .skills-content .span6 h2 {
    margin-bottom: 10px;
  }

  .skills-content .item-progress {
    margin: 10px 0 0;
  }

  .skills-content .item-progress .my-progress-bar {
    background: #ddd;
    overflow: hidden;
  }

  .skills-content .item-progress .progress {
    margin-bottom: 0;
    position: relative;
  }

  /* ----------------- Porfolio ---------------- */
  #projects .content {
    padding-top: 30px;
    margin: 0 auto;
  }

  #projects .item {
    //width: 33.033%;
    float: left;
    margin: 0.15%;
  }

  .da-thumbs {
    margin: 30px 0;
  }

  .da-thumbs li {
    position: relative;
  }

  .da-thumbs a,
  .da-thumbs a img {
    display: block;
    position: relative;
  }

  .da-thumbs a {
    overflow: hidden;
  }

  .da-thumbs a div {
    position: absolute;
    background: url(assets/images/icon/zoom.png) rgba(43, 37, 37, 0.6) 50% 50% no-repeat;
    width: 100%;
    height: 100%;
  }

  /*contact page*/
  #contact h2 {
    margin: 15px 0 10px;
  }

  #contact ul li {
    margin: 0 0 8px;
  }

  #contact ul li span {
    font-size: 14px;
    padding-right: 10px;
    text-transform: uppercase;
  }

  #contact .form input,
  #contact .form textarea,
  #showcase .form input,
  #showcase .form textarea {
    width: 93%;
    height: 38px;
    line-height: 38px;
    margin: 0 0 10px;
    color: #232527;
    outline: 0;
    padding: 4px 3%;
    border-color: #C8DDDC;
    background: #EFF4F5;
  }

  #contact .form textarea,
  #showcase .form textarea {
    height: 150px;
  }

  #contact .form .button,
  #showcase .form .button {
    cursor: pointer;
    float: right;
    padding: 0;
    border: none;
    margin: 15px 0;
  }

  #map-canvas {
    width: 100%;
    margin: 30px auto;
    height: 450px;
  }

  #map-canvas img {
    max-width: none;
  }

  #contact .social_icon {
    margin: 25px 0 0;
  }

  .fa {
    font-size: 34px;
  }

  /*mobile*/
  @media (max-width: 1450px) {
    .menu_nav li.active,
    .menu_nav li {
      position: static;
    }

    .header li {
      width: 50px;
      -webkit-transform: translate(-60px);
      -moz-transform: translate(-60px);
      -ms-transform: translate(-60px);
      transform: translate(-60px);
    }

    .header li a span {
      display: none;
    }

    .fa {
      font-size: 34px;
    }
  }

  @media (max-width: 1300px) {
    .header {
      top: 0;
      text-align: center;
      left: 0;
      z-index: 1;
      width: 100%;
    }

    .borderScheme-1 .header li {
      border-radius: 10px;
    }

    .header li {
      display: inline-block;
      padding: 0 4px 0 0;
      margin: 5px 10px 0 0;
      background-color: rgba(255, 250, 250, 0.63);
      border-left: 7px solid #777;
    }

    .header li:hover,
    .header li.active,
    .header li {
      -webkit-transform: translate(0px);
      -moz-transform: translate(0px);
      -ms-transform: translate(0px);
      transform: translate(0px);
    }

    .header li a {
      padding: 0 0 0 14px;
    }

    .main .page {
      padding-top: 60px;
    }

    .fa {
      font-size: 34px;
    }
  }

  @media (max-width: 1250px) {
    #home .button_box {
      margin: 0 0 0 0;
    }

    #home .button_box a.button {
      display: block;
      margin: 0 0 10px 0;
      width: 100%;
    }
    
    .fa {
      font-size: 34px;
    }
  }

  @media (max-width: 979px) {
    #home .button_box {
      margin: 10px 0 0 0;
    }
    .social_icon li {
      width: 50%;
    }

    #home .social_icon {
      margin: 0 0 15px;
    }

    .social_icon li {
      margin: 0;
    }

    /* ------- Contact Page------ */
    #contact .social_icon {
      margin: 0;
    }

    #contact .form textarea,
    #showcase .form textarea {
      height: 180px;
    }

    #contact .form .button,
    #showcase .form .button {
      margin: 0;
    }

    #contact .social_icon li {
      margin: 0;
    }

    #map-canvas {
      height: 280px;
    }

    .fa {
      font-size: 34px;
    }
  }

  @media (max-width: 800px) {
    .social_icon {
      text-align: center;
    }

    .social_icon li {
      width: 30%;
    }
    
    #home .social_icon {
      margin: 0 0 15px;
    }

    .social_icon li {
      margin: 0;
    }

    /* ------- Contact Page------ */
    #contact .social_icon {
      margin: 0;
    }

    #contact .form textarea,
    #showcase .form textarea {
      height: 180px;
    }

    #contact .form .button,
    #showcase .form .button {
      margin: 0;
    }

    #contact .social_icon li {
      margin: 0;
    }

    #map-canvas {
      height: 280px;
    }

    .fa {
      font-size: 34px;
    }
  }

  @media (max-width: 767px) {
    .header {
      position: static;
    }

    .main .page {
      padding-top: 10px;
    }

    /* ------- Home page ------ */
    #home .social_icon {
      margin: 0 auto;
    }

    .social_icon li {
      width: 30%;
    }

    #home .home-content .avatar {
      width: 50%;
    }

    .info {
      overflow: hidden;
    }

    .info li {
      width: 50%;
      float: left;
    }

    h2 {
      margin: 15px 0 0;
    }

    .main .skills-content h3 {
      margin: 20px 0 0;
    }

    /* ------ Contact page ------ */
    #contact .form,
    #showcase .form {
      overflow: hidden;
    }

    #contact .form .button,
    #showcase .form .button {
      float: none;
      margin: 0 auto;
      display: block;
    }

    #contact .info {
      margin: 0 0 15px;
    }

    .fa {
      font-size: 34px;
    }
  }

  @media (max-width: 480px) {
    body {
      min-width: 320px;
    }

    .social_icon {
      text-align: center !important;
    }

    .social_icon li {
      width: 30%;
    }

    .info li {
      width: 100%;
    }

    #home p,
    #projects p {
      text-align: justify;
    }

    .main .page {
      padding-top: 30px;
    }

    .fa {
      font-size: 34px;
    }
  }

  h2.not-top-margin {
    margin-top: 0;
  }

`;

const CustomGlobalStyle = createGlobalStyle`
/*!
* Custom style v2.0.0
* Created by Muzikayise Flynn Buthelezi on 2021/09/24. 
* mfbproject.co.za - muzi@mfbproject.co.za
* Copyright MFBproject
*/

  /* custom */
  .mfb_port_link-01{
    color: #fff !important;
  }

  .mfb-display-none-01{
    display: none;
  }

  .mfb-copy-01{
    background-color: #2f3238;
    color: #f2f2f2;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
  }

  body {
    font-size: 16px !important;
    line-height: 27px !important;
    font-family: 'Open Sans', sans-serif !important;
    color: #424142 !important;
  }

  ul.style-list {
    font-size: 20px !important;
    font-family: Oswald,sans-serif !important;
    list-style: disc !important;
    margin-left: 20px !important;
    li {
      list-style: disc !important;
      margin-top: 13px !important;
      font-style: italic;
      ul {
        list-style: disc !important;
        margin-left: 20px !important;
        li {
          list-style: circle !important;
          margin-top: 13px !important;
          font-style: italic;
        }
      }
    }
  }

  .sig-font-01{
    font-family: Oswald,sans-serif !important;
    font-style: italic;
  }

  a.blog-links-01:hover{
    text-decoration: none !important;
  }

  .blog-links-01 {
    color: #f22307 !important;
    border-bottom: 1px dotted #f22307 !important;
    border-top: 1px dotted #f22307 !important;
  }

  .profile-font-01{
    font-family: Oswald,sans-serif !important;
    font-size: 17px !important;
    text-transform: capitalize !important;
  }

  ul.info-01{
    li {
      p {
        margin: 10px 0 0 !important;
        padding: 0 20px 0 0 !important;
        letter-spacing: 0.0625em;
      }
    }
  }

  .bottom-margin {
    margin: 0 0 1em 0 !important;
  }

  .info-disc li {
    list-style: circle !important;
    margin: 0 0 8px 16px !important;
  }

  .react-tunes-player, .react-swift-slider {
    border: 0;
    width: 100%;
  }

  .react-tunes-player {
    height: 80px;
  }

  .react-swift-slider {
    height: 900px;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle
      icons={icons}
      showcaseIcon={showcaseIcon}
      skillsIcon={skillsIcon}
      projectsIcon={projectsIcon}
      contactIcon={contactIcon}
      homeIcon={homeIcon}
    />
    <CustomGlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

ReactGA.pageview(`${window.location.pathname}${window.location.search}`);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendWebVitalsToGoogleAnalytics);
