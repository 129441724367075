import ReactGA from 'react-ga';
import { Metric } from 'web-vitals/src/types';

ReactGA.initialize('UA-46134054-1 ');

const sendWebVitalsToGoogleAnalytics = ({ name, delta, id }: Metric) => {
  ReactGA.ga('send', 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventLabel: id,
    eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
    nonInteraction: true,
    transport: 'beacon',
  });
};

export { sendWebVitalsToGoogleAnalytics, ReactGA };
